import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AppRouter from '../../App.router';
import theme from '../../theme/mui-theme';
import useStyles from './AppTemplate.style';
import Footer from './Footer/Footer.component';
import Header from './Header/Header.component';
import ScrollRestoration from './ScrollRestoration/ScrollRestoration.component';

function AppTemplate() {
  const classes = useStyles();
  // console.info('MUI Theme: \t', theme);
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollRestoration />
          <CssBaseline />
          <div className={classes.root}>
            <Header />
            <div className={classes.contentContainer}>
              <AppRouter />
            </div>
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  );
}

AppTemplate.propTypes = {};

export default AppTemplate;
