import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      menuItem: {
        textTransform: 'capitalize',
        margin: `0 ${theme.spacing(0.5)}px`,
        transition: `0.15s ease`,
        color: theme.palette.common.black,
      },
      borderHover: {
        borderRadius: 0,
        '&:hover': {
          borderBottom: `3px solid ${theme.palette.primary.main}`,
          marginTop: -3,
        },
      },
    }),
  { name: 'DesktopNav' },
);

export default useStyles;
