import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        background: theme.palette.grey.A400,
        color: theme.palette.getContrastText(theme.palette.grey.A400),
        padding: theme.spacing(4),
        paddingTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(5),
      },
      contentContainer: {
        width: '100%',
        maxWidth: theme.breakpoints.values.lg,
      },

      logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        // marginBottom: theme.spacing(4),
        '& img': {
          maxHeight: theme.spacing(12),
        },
      },
      text: {
        fontSize: '0.7rem',
        margin: `${theme.spacing(2)}px 0`,
        '& a': {
          '&:hover': {
            textDecoration: 'none',
          },
          color: 'inherit',
        },
      },
      certImgContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& img': {
          height: 75,
          marginBottom: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      },
      copyrightContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
      },
    }),
  { name: 'Footer.component' },
);

export default useStyles;
