import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useStyles from './SearchBar.style';

function SearchBar({
  className,
  disableFilterButton = false,
  handleExecuteSearch,
  handleToggleFiltersDrawer,
  numberOfResults = 0,
  query = '',
  handleUpdateQuery,
}: {
  className?: string;
  disableFilterButton?: boolean;
  handleExecuteSearch?: any;
  handleToggleFiltersDrawer?: any;
  numberOfResults?: number;
  query: string;
  handleUpdateQuery: any;
}) {
  const classes = useStyles();
  const [localQuery, setLocalQuery] = useState('');

  if (useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))) {
    disableFilterButton = true;
  }

  const debounceHandleUpdateQuery = (event: any) => {
    const value = event.target.value;
    setLocalQuery(value);
    handleUpdateQuery(value);
  };

  const clearSearch = () => {
    setLocalQuery('');
    handleUpdateQuery('');
  };

  const handleLocalExecuteSearch = () => {
    handleExecuteSearch(localQuery);
  };

  return (
    <>
      <Paper component="form" className={clsx(classes.root, className)} elevation={0}>
        <InputBase
          className={classes.input}
          placeholder="Search Products"
          inputProps={{ 'aria-label': 'search products' }}
          value={localQuery}
          onChange={debounceHandleUpdateQuery}
          autoFocus
        />
        <Tooltip title="Clear search">
          <div>
            <IconButton
              className={classes.iconButton}
              aria-label="clear search"
              disabled={query.length === 0}
              onClick={clearSearch}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Divider className={classes.divider} orientation="vertical" />
        {!disableFilterButton && (
          <React.Fragment>
            <Tooltip title="Open filters">
              <IconButton
                className={classes.iconButton}
                aria-label="open filters"
                onClick={handleToggleFiltersDrawer}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Divider className={classes.divider} orientation="vertical" />
          </React.Fragment>
        )}
        <Tooltip title="Search">
          <IconButton
            className={classes.iconButton}
            color="primary"
            aria-label="search"
            onClick={handleLocalExecuteSearch}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <Typography className={classes.resultsAmount} variant="caption" paragraph>
        {numberOfResults} results
      </Typography>
    </>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  disableFilterButton: PropTypes.bool,
  handleExecuteSearch: PropTypes.func,
  handleToggleFiltersDrawer: PropTypes.func,
  handleUpdateQuery: PropTypes.func,
  query: PropTypes.string,
};

export default SearchBar;
