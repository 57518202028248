import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppConfig from './App.config';
import NotFound404Page from './pages/NotFound404/NotFound404.page';

export default function AppRouter() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  // console.log('Location: \t', location);

  let docTitle: string = 'Thermocontrol, Inc.';
  switch (location.pathname) {
    case '/':
      break;
    case '/products':
      docTitle = `Products | ${docTitle}`;
      break;
    case '/services':
      docTitle = `Services | ${docTitle}`;
      break;
    case '/suppliers':
      docTitle = `Suppliers | ${docTitle}`;
      break;
    case '/quality':
      docTitle = `Quality | ${docTitle}`;
      break;
    case '/about':
      docTitle = `About | ${docTitle}`;
      break;
    case '/contact':
      docTitle = `Contact | ${docTitle}`;
      break;

    default:
      docTitle = `404 | ${docTitle}`;
      break;
  }

  document.title = docTitle;

  return (
    <Routes>
      {AppConfig.mainNav.map((page) => (
        <Route key={page.id} path={page.path} element={<page.element />} />
      ))}
      <Route path="/*" element={<NotFound404Page />} />
    </Routes>
  );
}
