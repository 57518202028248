import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      productGridContainer: {
        marginTop: theme.spacing(2),
      },
      productCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxShadow: theme.shadows[4],
      },
      productCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
      },
      productCardImg: {
        boxShadow: theme.shadows[3],
        position: 'relative',
      },
      imgPlaceHolder: {
        height: 140,
      },
      productCardContent: {
        width: '100%',
        background: theme.palette.grey[100],
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 0,
      },
      cardActions: {
        background: theme.palette.grey[100],
        padding: 16,
      },
      noResultsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '20vh',
        flexDirection: 'column',
      },
      noResultsTypography: {
        fontVariant: 'all-small-caps',
      },
    }),
  { name: 'ProductGrid' },
);

export default useStyles;
