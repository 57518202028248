import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import AppConfig from '../../../App.config';
import useStyles from './ContactInfoList.style';

function ContactInfoList({ darkTheme = false }: { darkTheme?: boolean }) {
  const classes = useStyles();

  return (
    <List disablePadding dense>
      {AppConfig.contacts.map((contact) => (
        <ListItem key={contact.title} button component="a" href={contact.href}>
          <ListItemIcon className={clsx(classes.iconContainer, darkTheme && classes.dark)}>
            <FontAwesomeIcon className={classes.icon} icon={contact.icon} />
          </ListItemIcon>
          <ListItemText primary={contact.title} />
        </ListItem>
      ))}
    </List>
  );
}

export default ContactInfoList;
