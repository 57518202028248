import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import itemsjs from 'itemsjs';
import { debounce, findIndex } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import AppConfig from '../../App.config';
import ContentSectionWrapper from '../../components/AppTemplate/ContentSectionWrapper/ContentSectionWrapper.component';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';
import FilterList from '../../components/FilterList/FilterList.component';
import ProductGrid from '../../components/ProductGrid/ProductGrid.component';
import SearchBar from '../../components/SearchBar/SearchBar.component';
import { uniqueStringArray } from '../../utils/utils';
import useStyles from './Products.page.style';

function ProductsPage() {
  const classes = useStyles();
  const [isFiltersDrawerIn, setIsFiltersDrawerIn] = useState(false);
  const [areFiltersInGrid, setAreFiltersInGrid] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(AppConfig.products);

  // Filters state management
  const applicationsUnique: string[] = uniqueStringArray(AppConfig.products, 'applications').sort();
  const airframesUnique: string[] = uniqueStringArray(AppConfig.products, 'airframes').sort();
  const mapStringArrayFilterObj = (arr: string[]) =>
    arr.map((title, i) => ({ title, id: i.toString(), isChecked: false }));
  const [filterData, setFilterData] = useState([
    {
      id: 'applications',
      title: 'Application',
      subsections: mapStringArrayFilterObj(applicationsUnique),
    },
    { id: 'airframes', title: 'Airframe', subsections: mapStringArrayFilterObj(airframesUnique) },
  ]);

  const handleToggleFiltersDrawer = () => {
    setIsFiltersDrawerIn(!isFiltersDrawerIn);
  };

  const getFilterStatus = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  useEffect(() => {
    debounce(() => setAreFiltersInGrid(getFilterStatus), 50);
  }, [areFiltersInGrid, getFilterStatus]);

  let aggregations: any = {};

  AppConfig.searchConfig.filters.forEach((filter) => {
    aggregations = {
      ...aggregations,
      [filter.id]: {
        title: filter.title,
        conjunction: filter.isAndSearch,
        size: 10,
      },
    };
  });

  const itemsjsSearch = itemsjs(AppConfig.products, {
    sortings: {
      name_asc: {
        field: 'name',
        order: 'asc',
      },
    },
    aggregations,
    searchableFields: AppConfig.searchConfig.searchableFields,
  });

  const reduceFiltersItemJsSearch = (filters: any) => {
    filters = filters || filterData;

    let reducedFilters: any = {};
    filters.forEach((filter: any) => {
      reducedFilters = {
        ...reducedFilters,
        [filter.id]: filter.subsections
          .map((subsection: any) => {
            if (subsection.isChecked) return subsection.title;
          })
          .filter((title: string) => title),
      };
      if (reducedFilters[filter.id].length === 0) delete reducedFilters[filter.id];
    });
    return reducedFilters;
  };

  const executeSearch = ({ query, filters }: { query?: string; filters?: any }) => {
    query = query == null ? searchQuery : query;
    filters = reduceFiltersItemJsSearch(filters);

    const search = itemsjsSearch.search({
      per_page: 50,
      sort: 'name_asc',
      query,
      filters,
    });
    setSearchResults(search.data.items);
  };

  const handleUpdateQuery = debounce((query: string) => {
    setSearchQuery(query);
    executeSearch({ query });
  }, 200);

  const executeFilterCheck = (id: string) => {
    const [sectionId, subsectionId] = id.split('-');

    const sectionIndex = findIndex(filterData, (o) => o.id === sectionId);
    const subsectionIndex = findIndex(
      filterData[sectionIndex].subsections,
      (o) => o.id === subsectionId,
    );

    const newFilterData = [...filterData];

    newFilterData[sectionIndex].subsections[subsectionIndex].isChecked =
      !newFilterData[sectionIndex].subsections[subsectionIndex].isChecked;

    executeSearch({ filters: newFilterData });
    setFilterData(newFilterData);
  };

  return (
    <React.Fragment>
      <PageHeader />
      <ContentSectionWrapper title="Products">
        <Grid container spacing={4} className={classes.resultsRoot}>
          {areFiltersInGrid && (
            <Grid item xs={12} lg={3} className={classes.filtersContainer}>
              <Typography variant="h6">Filters</Typography>
              <Divider className={classes.divider} />
              <FilterList executeFilterCheck={executeFilterCheck} filters={filterData} />
            </Grid>
          )}
          <Grid item xs={12} lg={9} className={classes.searchBarContainer}>
            <SearchBar
              className={classes.searchBar}
              handleToggleFiltersDrawer={handleToggleFiltersDrawer}
              numberOfResults={searchResults.length}
              handleUpdateQuery={handleUpdateQuery}
              query={searchQuery}
              handleExecuteSearch={executeSearch}
            />
            <ProductGrid products={searchResults} isPreview={false} />
          </Grid>
        </Grid>
        <Drawer open={isFiltersDrawerIn} onClose={handleToggleFiltersDrawer}>
          <div className={classes.drawerFiltersContainer}>
            <div className={classes.drawerFiltersTitle}>
              <Typography variant="h6">Filters</Typography>
              <Tooltip title="Close filters" placement="left">
                <IconButton
                  size="small"
                  aria-label="close filters"
                  onClick={handleToggleFiltersDrawer}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Divider className={classes.divider} />
            {/* <FilterList executeFilterCheck={executeFilterCheck} /> */}
          </div>
        </Drawer>
      </ContentSectionWrapper>
    </React.Fragment>
  );
}

export default ProductsPage;
