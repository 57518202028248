import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      caret: {
        marginRight: theme.spacing(1),
      },
      highlightsGrid: {
        [theme.breakpoints.up('sm')]: {
          borderLeft: `1px solid ${theme.palette.divider}`,
        },
      },
      highlightContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        margin: theme.spacing(3),
        width: '100%',
        maxWidth: 400,
      },
      avatar: {
        height: 100,
        width: 100,
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        boxShadow: theme.shadows[7],
        marginBottom: theme.spacing(3),
        '& svg': {
          height: '45%',
        },
      },
      highlightsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        justifyContent: 'center',
      },
      highlightTextContainer: {
        '& h5': {
          marginBottom: theme.spacing(2),
        },
        marginBottom: theme.spacing(3),
      },
      certImgContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& img': {
          height: 75,
          marginBottom: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
      },
    }),
  { name: 'Services.page' },
);

export default useStyles;
