import useStyles from './PageHeader.style';

export default function PageHeader() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <img src="/images/f18-vertical.webp" />
    </section>
  );
}
