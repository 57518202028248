import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import PropTypes from 'prop-types';
import useStyles from './FilterList.style';

function FilterList({ executeFilterCheck, filters }: { executeFilterCheck: any; filters: any[] }) {
  const classes = useStyles();

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      disableSelection
      defaultExpanded={'0,1'.split(',')}
    >
      {filters.map((section, i) => (
        <TreeItem
          key={`${i}`}
          nodeId={`${i}`}
          label={section.title}
          classes={{ root: classes.sectionRoot, label: classes.sectionLabel }}
        >
          <List dense>
            {section.subsections.map((subsection: any, j: number) => (
              <ListItem
                key={`${i}:${j}`}
                role={undefined}
                dense
                button
                onClick={() => executeFilterCheck(`${section.id}-${subsection.id}`)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `filter-${i}:${j}` }}
                    checked={subsection.isChecked}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`filter-${i}:${j}`}
                  primary={subsection.title}
                  // secondary={Math.round(Math.random() * 1000)}
                />
              </ListItem>
            ))}
          </List>
        </TreeItem>
      ))}
    </TreeView>
  );
}

FilterList.propTypes = {
  children: PropTypes.node,
};

export default FilterList;
