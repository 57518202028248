import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './MobileNav.style';

function MobileNav({ menuItems }: { menuItems: any[] }) {
  const classes = useStyles();
  let skipIndex: number = -1;

  const [state, setState] = React.useState({
    isOpen: false,
  });

  const handleToggleDrawer = () => {
    setState({ isOpen: !state.isOpen });
  };

  return (
    <React.Fragment>
      <Tooltip title="Open navigation" placement="left">
        <IconButton onClick={handleToggleDrawer} aria-label="open navigation">
          <MenuIcon fontSize="inherit" color="primary" />
        </IconButton>
      </Tooltip>
      <Drawer anchor="right" open={state.isOpen} onClose={handleToggleDrawer}>
        <div className={classes.list}>
          <List>
            <ListItem className={classes.topLink}>
              <Link to="/" className={classes.homeLink} onClick={handleToggleDrawer}>
                <img
                  className={classes.logoImage}
                  src="/images/logo.webp"
                  alt="Thermocontrol Logo"
                />
              </Link>
              <Tooltip title="Close menu" placement="left">
                <ListItemIcon aria-label="close menu" onClick={handleToggleDrawer}>
                  <IconButton size="small">
                    <CloseIcon />
                  </IconButton>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
            <Divider />
            {menuItems.map((item, i) => {
              if (i === skipIndex) {
                return null;
              }
              if (item.type === 'grid') {
                skipIndex = i + 1;
                return (
                  <ListItem key={item.id}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Button
                          component={Link}
                          to={item.path || '#'}
                          variant="contained"
                          color="primary"
                          fullWidth
                          disableElevation
                        >
                          {item.label}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          component={Link}
                          to={menuItems[skipIndex].path || '#'}
                          variant="outlined"
                          color="primary"
                          fullWidth
                        >
                          {menuItems[skipIndex].label}
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              }
              return (
                <ListItem
                  component={Link}
                  to={item.path}
                  onClick={handleToggleDrawer}
                  key={item.id}
                  button
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

MobileNav.propTypes = {
  menuItems: PropTypes.array,
};

export default MobileNav;
