import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const standardAllEaseOut = (theme: any) =>
  `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut} 20.1s`;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      // Beta Banner Styles
      beta: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.getContrastText(theme.palette.info.dark),
        textDecoration: 'none',
      },
      betaText: {
        fontWeight: 'bold',
        fontVariant: 'all-small-caps',
      },
      betaToolbarDense: { minHeight: 28 },

      // Styles
      appBar: {
        transition: standardAllEaseOut(theme),
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      appBarBg: {
        transition: standardAllEaseOut(theme),
        backgroundColor: theme.palette.grey[200],
        boxShadow: theme.shadows[15],
      },
      menuButton: { marginRight: theme.spacing(2) },
      title: { fontWeight: 'bold' },
      logoImage: {
        maxHeight: 64,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          maxHeight: 32,
        },
      },
      homeLinkContainer: { flexGrow: 1 },
      homeLink: {
        width: 'fit-content',
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        alignItems: 'center',
      },
      mobileNavContainer: {
        [theme.breakpoints.up('lg')]: { display: 'none' },
      },
      desktopNavContainer: {
        [theme.breakpoints.down('md')]: { display: 'none' },
      },
    }),
  { name: 'Header' },
);

export default useStyles;
