import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@material-ui/core';
import ContentSectionWrapper from '../../components/AppTemplate/ContentSectionWrapper/ContentSectionWrapper.component';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';

function SuppliersPage() {
  return (
    <>
      <PageHeader />
      <ContentSectionWrapper title="Suppliers">
        <Typography variant="body1" paragraph>
          The QAP PDF is the list of definitions and explanations of the Quality Assurance
          Procurement Codes that are marked on Requisitions and Purchase Orders, when the purchase
          requires “Certification” with the material. The use of these codes communicates
          Thermocontrol Inc.’s Quality Assurance requirements, as conditions of shipment acceptance,
          to the Vendor.
        </Typography>
        <Typography variant="body1" paragraph>
          This list includes all codes currently used on Thermocontrol, Inc. Requisitions and
          Purchase Orders. Each code is activated for the concerned Requisition and Purchase Order
          by the Quality Assurance manager checking the corresponding block and initialing the QAM
          APPROVAL: space.
        </Typography>
        <Typography variant="body1" paragraph>
          The current Thermocontrol, Inc. Quality Assurance Provisions (QAP's) including Terms and
          Conditions (QAP 26) is available in PDF format.
        </Typography>
        <Button
          component="a"
          href="/docs/thermocontrol-qap.pdf"
          variant="outlined"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faFilePdf} />}
        >
          Download QAP
        </Button>
      </ContentSectionWrapper>
    </>
  );
}

export default SuppliersPage;
