import clsx from 'clsx';
import useStyles from './BoxDivider.style';

function BoxDivider({ className }: { className?: string }) {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)} />;
}

BoxDivider.propTypes = {};

export default BoxDivider;
