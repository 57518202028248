import { Button, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import clsx from 'clsx';
import { find } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AppConfig from '../../App.config';
import ContactInfoList from '../../components/AppTemplate/ContactInfoList/ContactInfoList.component';
import ContentSectionWrapper from '../../components/AppTemplate/ContentSectionWrapper/ContentSectionWrapper.component';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';
import useStyles from './Contact.page.style';

function ContactPage() {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isInvalidName, setIsInvalidName]: [isInvalidName: boolean | null, setIsInvalidName: any] =
    useState(null);
  const [isInvalidEmail, setIsInvalidEmail]: [
    isInvalidEmail: boolean | null,
    setIsInvalidEmail: any,
  ] = useState(null);
  const [isInvalidSubject, setIsInvalidSubject]: [
    isInvalidSubject: boolean | null,
    setIsInvalidSubject: any,
  ] = useState(null);
  const [isInvalidMessage, setIsInvalidMessage]: [
    isInvalidMessage: boolean | null,
    setIsInvalidMessage: any,
  ] = useState(null);

  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [queryParams, setQueryParams] = useSearchParams();
  useEffect(() => {
    const quoteID = queryParams.get('quoteID');
    if (quoteID) {
      const product = find(AppConfig.products, (p) => p.id.toString() === quoteID);
      if (product) {
        setSubject(`[RFQ] ${product.number_tci}: ${product.name}`);
        console.log(typeof quoteID, quoteID);
      }
      setQueryParams(undefined);
    }
  });

  const validateEmail = (emailAddress: string): boolean => {
    return Boolean(
      emailAddress.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
    );
  };

  const handleTextChange = (e: any): void => {
    const { id, value } = e?.target;

    switch (id) {
      case 'name':
        setName(value);
        setIsInvalidName(!value);
        setCanSubmit(!isInvalidEmail && !isInvalidSubject && !isInvalidMessage && value);
        break;
      case 'email':
        setEmail(value);
        setIsInvalidEmail(!validateEmail(value));
        setCanSubmit(
          !isInvalidName && !isInvalidSubject && !isInvalidMessage && validateEmail(value),
        );
        break;
      case 'subject':
        setSubject(value);
        setIsInvalidSubject(!value);
        setCanSubmit(!isInvalidName && !isInvalidEmail && !isInvalidMessage && value);
        break;
      case 'message':
        setMessage(value);
        setIsInvalidMessage(!value);
        setCanSubmit(!isInvalidName && !isInvalidEmail && !isInvalidSubject && value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (canSubmit) {
      setIsSubmitting(true);
      axios
        .get(`${AppConfig.api}/v1/contact/`, {
          params: {
            name,
            email,
            subject,
            message,
          },
        })
        .then((resp) => {
          setSubject('');
          setMessage('');
          setIsInvalidSubject(null);
          setIsInvalidMessage(null);

          setSubmitSuccess(true);
        })
        .catch((resp) => {
          setSubmitSuccess(false);
          console.error(resp);
        })
        .finally(() => {
          setHasSubmitted(true);
          setIsSubmitting(false);
        });
    }
  };

  const handleResetSubmit = () => {
    setHasSubmitted(false);
    setCanSubmit(false);
  };

  const handleResetFailedSubmit = () => {
    setHasSubmitted(false);
  };

  return (
    <>
      <PageHeader />
      <div className={classes.boxShadow}>
        <ContentSectionWrapper title="Contact">
          <Grid container spacing={3}>
            <Grid item sm={12} md={8}>
              {hasSubmitted && submitSuccess && (
                <div className={classes.submitSuccessContainer}>
                  <Typography variant="h3">SUCCESS</Typography>
                  <br />
                  <Typography variant="body1">
                    Your request has been submitted. One of our team members will follow up with you
                    in 1-3 business days.
                  </Typography>
                  <br />
                  <Button variant="contained" color="primary" onClick={handleResetSubmit}>
                    Submit Another Request
                  </Button>
                </div>
              )}
              {hasSubmitted && !submitSuccess && (
                <div className={classes.submitSuccessContainer}>
                  <Typography variant="h3">ERROR</Typography>
                  <br />
                  <Typography variant="body1">
                    Your request has been NOT been submitted. An error has occurred. Please try
                    again.
                  </Typography>
                  <Typography variant="body1">
                    If this issue persists, please email us directly and notify us of this error.
                  </Typography>
                  <Typography variant="body1">Thank you!</Typography>
                  <br />
                  <Button variant="contained" color="primary" onClick={handleResetFailedSubmit}>
                    Retry Submission
                  </Button>
                </div>
              )}
              {!hasSubmitted && (
                <>
                  <Typography variant="body1" paragraph>
                    Use the below form to submit a request. One of our team members will follow up
                    with you in 1-3 business days.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} lg={6} xs={12}>
                      <TextField
                        className={clsx(classes.textFieldPaper)}
                        variant="outlined"
                        required
                        fullWidth
                        label="Name"
                        id="name"
                        value={name}
                        error={Boolean(isInvalidName && name.length != null)}
                        helperText={
                          isInvalidName && name.length != null ? 'Name is required' : null
                        }
                        onChange={handleTextChange}
                      />
                    </Grid>
                    <Grid item sm={12} lg={6} xs={12}>
                      <TextField
                        className={clsx(classes.textFieldPaper)}
                        variant="outlined"
                        required
                        fullWidth
                        label="Email"
                        id="email"
                        value={email}
                        error={Boolean(isInvalidEmail && email.length != null)}
                        helperText={
                          isInvalidEmail && email.length != null
                            ? 'Enter a valid email address'
                            : null
                        }
                        onChange={handleTextChange}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    className={classes.textFieldPaper}
                    variant="outlined"
                    required
                    fullWidth
                    label="Subject"
                    id="subject"
                    value={subject}
                    error={Boolean(isInvalidSubject && subject.length != null)}
                    helperText={
                      isInvalidSubject && subject.length != null ? 'Subject is required' : null
                    }
                    onChange={handleTextChange}
                  />
                  <TextField
                    className={classes.textFieldPaper}
                    variant="outlined"
                    required
                    fullWidth
                    label="Message"
                    id="message"
                    multiline
                    minRows={10}
                    value={message}
                    error={Boolean(isInvalidMessage && message.length != null)}
                    helperText={
                      isInvalidMessage && message.length != null ? 'Message is required' : null
                    }
                    onChange={handleTextChange}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                    className={classes.submitButton}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </>
              )}
            </Grid>
            <Grid item sm={12} md={4} className={classes.contactListContainer}>
              <ContactInfoList />
            </Grid>
          </Grid>
        </ContentSectionWrapper>
      </div>
      <iframe
        className={classes.gmapsIframe}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.483747932972!2d-95.54406048738343!3d29.879158874902938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640cfeac5a30c5b%3A0x14d2a3ce6d71c70b!2s9600%20W%20Gulf%20Bank%20Rd%2C%20Houston%2C%20TX%2077040!5e0!3m2!1sen!2sus!4v1725824751978!5m2!1sen!2sus"
        style={{ border: 0 }}
        loading="lazy"
      ></iframe>
    </>
  );
}

export default ContactPage;
