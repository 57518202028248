import { Grid, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppConfig from '../../../App.config';
import ContactInfoList from '../ContactInfoList/ContactInfoList.component';
import useStyles from './Footer.style';

export default function PageFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.logoContainer}>
          <img src="/images/logo-white.webp" alt="Thermocontrol, Inc. Logo" />
        </div>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={3} md={6}>
            <ContactInfoList darkTheme />
          </Grid>
          <Grid item sm={12} lg={2} md={6}>
            <List disablePadding dense>
              {AppConfig.mainNav.map((nav) => (
                <ListItem key={nav.id} button component="a" href={nav.path}>
                  <ListItemText primary={nav.label} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item sm={12} lg={7} className={classes.certImgContainer}>
            {AppConfig.footerCertifications.map((logo) => (
              <img key={logo.imgSrc} src={logo.imgSrc} alt={logo.altText} />
            ))}
          </Grid>
        </Grid>
        <div className={classes.copyrightContainer}>
          <Typography variant="caption" color="inherit">
            Thermocontrol, Inc. &copy; {new Date().getFullYear()} | All Rights Reserved
          </Typography>
        </div>
      </div>
    </footer>
  );
}
