import { faAward, faCompassDrafting, faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import AppConfig from '../../App.config';
import ContentSectionWrapper from '../../components/AppTemplate/ContentSectionWrapper/ContentSectionWrapper.component';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';
import useStyles from './Services.page.style';

function ServicesPage() {
  const classes = useStyles();
  return (
    <>
      <PageHeader />
      <ContentSectionWrapper disablePadding>
        <Grid container>
          <Grid item sm={12} md={8}>
            <ContentSectionWrapper title="Capabilities">
              <Typography paragraph>
                Because traceability, <Link to="/quality">quality control</Link>, and quick, timely
                delivery are important, Thermocontrol, Inc. is one of few aerospace temperature
                instrumentation manufacturers that manufactures almost entirely in house with
                traceability down to raw material. Thermocontrol, Inc. is NADCAP accredited in
                welding, brazing, and heat-treating services, is AS9100 & ISO 9001 certified which
                guarantees quality at every step of the process, and meets various customer quality
                requirements. In addition, Thermocontrol, Inc. is a certified Woman and
                Minority-Owned Small Business.
              </Typography>
              <Typography paragraph>
                Thermocontrol, Inc. originated with the development of thermocouples and
                thermocouple harnesses for aircraft turbine engines. <Link to ="/products">Today, the company also
                manufactures cockpit and avionics bay temperature controllers, thermocouple lead
                assemblies, and flexible harnesses for fixed-wing, rotor, space craft, and land and
                maritime power generation turbines.</Link>
              </Typography>
            </ContentSectionWrapper>  
            <ContentSectionWrapper title="Design And Development">
              <Typography paragraph>
                Thermocontrol offers built-to-print services to meet OEM products and processes. 
                We adhere to strict industry and OEM standards through the review, design, and 
                manufacutring phases of new and existing components and services.
              </Typography>
              <Typography paragraph>
                Through this process, we provide:
              </Typography>
              <ul>
                <li>
                  <Typography>Rapid Prototyping</Typography>
                </li>
                <li>
                  <Typography>Built-to-Print</Typography>
                </li>
                <li>
                  <Typography>Pricing and Delivery Estimation</Typography>
                </li>
              </ul>
              <Typography paragraph>
                Our team is dedicated in providing the design, development, prototyping,
                manufacture, quality control, and on time delivery of aerospace instrumentation. We
                have partnered with several manufacturers and military personnel for new and
                redesigned products and look forward to future opportunities.
              </Typography>
            </ContentSectionWrapper>
          </Grid>
          <Grid item sm={12} md={4} className={classes.highlightsGrid}>
          <ContentSectionWrapper title="Manufacturing Services">
              <Typography paragraph>
                Thermocontrol, Inc. can provide the following manufacturing services with its
                products:
              </Typography>
              <ul>
                <li>
                  <Typography>Precision Machining</Typography>
                  <ul>
                    <li>
                        <Typography>CNC/Manual Milling</Typography>
                    </li>
                    <li>
                        <Typography>CNC/Manual Lathe</Typography>
                    </li>
                    <li>
                        <Typography>CNC Mill Turn</Typography>
                    </li>
                    <li>
                        <Typography>CNC/Manual Tube Bending</Typography>
                    </li>
                    <li>
                        <Typography>Swaging</Typography>
                    </li>
                    <li>
                        <Typography>Waterjet</Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>NADCAP Accredited Welding (Automatic & Manual)</Typography>
                  <ul>
                    <li>
                        <Typography>GTAW - Gas Tungsten Arc Welding</Typography>
                    </li>
                    <li>
                        <Typography>LBW - Laser Beam Welding</Typography>
                    </li>
                    <li>
                        <Typography>PAW - Plasma Arc Welding</Typography>
                    </li>
                    <li>
                        <Typography>Resistance Welding</Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>NADCAP Accredited Brazing (Torch & Induction)</Typography>
                    <ul>
                    <li>
                        <Typography>Vacuum Furnace</Typography>
                    </li>
                    <li>
                        <Typography>Induction</Typography>
                    </li>
                    <li>
                        <Typography>Torch</Typography>
                    </li>
                    </ul>
                </li>
                <li>
                  <Typography>NADCAP Accredited Annealing & Heat Treating</Typography>
                    <ul>
                    <li>
                        <Typography>Vacuum</Typography>
                    </li>
                    <li>
                        <Typography>Atmospheric</Typography>
                    </li>
                    </ul>
                </li>
                <li>
                  <Typography>Mineral Insulated (MI) Cable Manufacturing</Typography>
                </li>
                <li>
                  <Typography>Metal Overbraiding</Typography>
                </li>
                <li>
                  <Typography>Soldering and Electronic Assembly</Typography>
                </li>
                <li>
                  <Typography>Thermocouple and RTD Calibration</Typography>
                </li>
              </ul>
            </ContentSectionWrapper> 
          </Grid>
        </Grid>
      </ContentSectionWrapper>
    </>
  );
}

export default ServicesPage;
