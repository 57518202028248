import { createStyles, makeStyles } from '@material-ui/core/styles';

const standardAllEaseOut = (theme: any) =>
  `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut} 0.1s`;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      outerPadding: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(3),
        paddingBottom: 0,

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(3),
          paddingTop: theme.spacing(3),
          paddingBottom: 0,
        },
      },
      root: {
        maxWidth: theme.breakpoints.values.lg,
        transition: standardAllEaseOut(theme),
        [theme.breakpoints.up('lg')]: {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      sectionHeader: {
        marginBottom: theme.spacing(1),
      },
      boxDivider: {
        marginBottom: theme.spacing(3),
      },
    }),
  { name: 'ContentSectionWrapper' },
);

export default useStyles;
