import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      textFieldPaper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      contactListContainer: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down('sm')]: {
          borderLeft: 'none',
        },
      },
      gmapsIframe: {
        width: '100%',
        height: 450,
        marginBottom: -theme.spacing(5) - 7,
        zIndex: 0,
        background: theme.palette.grey[400],
      },
      boxShadow: {
        boxShadow: theme.shadows[4],
        zIndex: 100,
        position: 'relative',
        paddingTop: theme.spacing(5),
        marginTop: -theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
      submitSuccessContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: 300,
        padding: theme.spacing(3),
        textAlign: 'center',
      },
      submitButton: {
        minHeight: 37,
      },
      buttonProgress: {
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
    }),
  { name: 'Contact.page' },
);

export default useStyles;
