import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './DesktopNav.style';

export default function DesktopNav({ menuItems }: { menuItems: any[] }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      {menuItems.map((item) => (
        <Button
          component={Link}
          to={item.path || '#'}
          key={item.id}
          className={clsx(classes.menuItem, !item.type && classes.borderHover)}
          variant={item.variant}
        >
          {item.label}
        </Button>
      ))}
    </React.Fragment>
  );
}
