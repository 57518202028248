import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid, { GridSize } from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import useStyles from './ProductGrid.style';

function ProductGrid({
  isPreview = true,
  gridSize = 4,
  products,
}: {
  isPreview?: boolean;
  gridSize?: GridSize;
  products: any[];
}) {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.productGridContainer}>
      {products.length < 1 && (
        <div className={classes.noResultsContainer}>
          <Typography variant="h4" className={classes.noResultsTypography}>
            No results
          </Typography>
          <Typography variant="body1">
            Please remove some filters or modify the search query
          </Typography>
        </div>
      )}
      {products.length > 0 &&
        products.map((product: any) => (
          <Grid key={product.number_tci} item xs={12} md={6} lg={gridSize}>
            <Card className={classes.productCardContainer}>
              {/* <CardActionArea className={classes.productCardActionArea}> */}

              <CardMedia
                component="img"
                height="140"
                image={product.image || 'images/products/no-image.webp'}
                alt={`picture of ${product.name}`}
                className={classes.productCardImg}
              />
              <CardContent className={classes.productCardContent}>
                <Typography gutterBottom variant="h5" component="div">
                  {product.name}
                </Typography>
                <List dense disablePadding>
                  {product.number_tci && (
                    <ListItemText primary={product.number_tci} secondary="TCI P/N" />
                  )}
                  {!isPreview && product.number_oem && (
                    <ListItemText primary={product.number_oem} secondary="OEM P/N" />
                  )}
                  {!isPreview && product.number_nsn && (
                    <ListItemText primary={product.number_nsn} secondary="NSN P/N" />
                  )}
                </List>
              </CardContent>
              {/* </CardActionArea> */}
              {!isPreview && (
                <CardActions className={classes.cardActions}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={`/contact?quoteID=${product.id}`}
                  >
                    Quote
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}

export default ProductGrid;
