import { createStyles, makeStyles } from '@material-ui/core/styles';

const heroSectionHeight = 550;
const navHeight = 80;
const heroDividerHeight = 125;
const standardAllEaseOut = (theme: any) =>
  `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut} 0.1s`;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      heroSectionRoot: {
        position: 'relative',
        height: heroSectionHeight + navHeight,
        display: 'flex',
        justifyContent: 'center',
      },
      heroShapeDivider: {
        position: 'absolute',
        bottom: -heroDividerHeight,
        left: 0,
        width: '100%',
        overflow: 'hidden',
        lineHeight: 0,
        transform: 'rotate(180deg)',
        transition: standardAllEaseOut(theme),
        [theme.breakpoints.down('sm')]: { bottom: -heroDividerHeight / 3.25 },
        '& svg': {
          position: 'relative',
          display: 'block',
          width: '100%',
          transition: standardAllEaseOut(theme),
          height: 250,
          [theme.breakpoints.down('sm')]: { height: 125 },
        },
      },
      heroShapeFill: {
        fill: theme.palette.grey[200],
      },
      heroContainer: {
        maxWidth: theme.breakpoints.values.lg,
        margin: '0 auto',
        padding: theme.spacing(5),
        zIndex: 100,
        position: 'absolute',
        width: '100%',
        height: heroSectionHeight - navHeight,
        fontWeight: 'bold',
        color: theme.palette.common.black,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-end',
        textAlign: 'center',
        marginTop: navHeight,
        transition: standardAllEaseOut(theme),

        [theme.breakpoints.down('sm')]: {
          alignItems: 'center',
        },
      },

      hero: {
        position: 'absolute',
        padding: 24,
        borderRadius: theme.spacing(1),
        minWidth: 300,
        maxWidth: 'fit-content',
        width: '50%',
        backdropFilter: 'blur(5px)',
        transition: standardAllEaseOut(theme),

        [theme.breakpoints.down('sm')]: {
          background: 'rgb(255 255 255 / 45%)',
          boxShadow: theme.shadows[5],
          width: '100%',
          minWidth: '100%',
          maxWidth: '100%',
          borderRadius: 0,
        },
      },
      heroLogo: {
        maxWidth: '80%',
      },
      title: {
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
          fontSize: '3.25rem',
        },
      },
      subtitle: {
        marginBottom: 0,
        fontStyle: 'italic',
      },
      heroSubHeader: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(0.5),
        fontWeight: 'bold',
      },
      imageContainer: {
        zIndex: 0,
        height: heroSectionHeight + navHeight + heroDividerHeight,
        width: '-webkit-fill-available',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        transition: standardAllEaseOut(theme),
        [theme.breakpoints.down('sm')]: {
          height: heroSectionHeight + navHeight + heroDividerHeight / 3.35,
        },
      },
      image: {
        width: 'calc(100vw + 150px)',
        height: '100%',
        backgroundImage: 'url("/images/f18-vertical.webp")',
        margin: '0 0 0 -150px',
        backgroundSize: 'cover',
      },

      productsSectionRoot: {
        maxWidth: theme.breakpoints.values.lg,
        position: 'relative',
        padding: theme.spacing(5),
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: theme.spacing(3),
        transition: standardAllEaseOut(theme),
        [theme.breakpoints.up('lg')]: {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(3),
        },
      },
      productsSectionTitle: {
        marginBottom: theme.spacing(1),
      },
      showcaseTitleDivider: { margin: `${theme.spacing(0.5)}px 0 ${theme.spacing(3)}px` },
      productGridContainer: {
        marginTop: theme.spacing(2),
      },
      productCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      },
      productCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
      },
      productCardImg: {},
      productCardContent: {
        width: '100%',
        background: theme.palette.common.white,
        borderTop: '2px solid',
        borderTopColor: theme.palette.primary.light,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      productsMoreButtonContainer: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        '& button': {
          maxWidth: 300,
          flex: 1,
        },
      },
      servicesContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },

      inceptionContainer: {
        height: 400,
        [theme.breakpoints.down('md')]: {
          height: 500,
        },
        [theme.breakpoints.down('xs')]: {
          height: 700,
        },
      },
      inceptionContentRoot: {
        maxWidth: theme.breakpoints.values.lg,
        width: '70vw',
        textAlign: 'center',
        color: theme.palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        [theme.breakpoints.down('sm')]: {
          width: '100vw',
          padding: theme.spacing(5),
        },
      },
      inceptionTitle: {
        background: theme.palette.grey[200],
        color: 'black',
        padding: theme.spacing(2),
        maxWidth: 625,
        // textAlign: 'left',
        margin: 'auto',
        zIndex: 200,
        position: 'relative',
      },
      inceptionContent: {
        backdropFilter: 'blur(8px)',
        position: 'relative',
        padding: theme.spacing(3),
        background: 'rgb(0 0 0 / 65%)',
        paddingTop: theme.spacing(8),
        marginTop: -theme.spacing(5),
        zIndex: 100,
      },
      inceptionImageBg: {
        opacity: 0.7,
      },
      dlaSectionRoot: {
        maxWidth: theme.breakpoints.values.lg,
        position: 'relative',
        padding: theme.spacing(5),
        [theme.breakpoints.up('lg')]: {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      dlaContentGrid: {
        marginTop: theme.spacing(3),
      },
      dlaContent: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
      },
      dlaImageCell: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
      dlaAboutButton: {
        alignSelf: 'center',
      },
      negFooterOffset: {
        marginBottom: -theme.spacing(5),
      },
    }),
  { name: 'Home.page' },
);

export default useStyles;
