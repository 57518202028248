import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: theme.palette.grey[200],
      },
      contentContainer: {
        flexGrow: 1,
        // background: theme.palette.grey[200],
      },
    }),
  { name: 'AppTemplate' },
);

export default useStyles;
