import { Grid, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';
import useStyles from './NotFound404.page.style';

function NotFound404Page() {
  const classes = useStyles();
  return (
    <>
      <PageHeader />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs md={4} className={classes.svgContainer}>
            <img
              className={classes.earthSvg}
              src="/images/404-globe2.svg"
              alt="404 Page Not Found Graphic"
            />
          </Grid>
          <Grid item className={classes.contentContainer} xs={12} md={8}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h3">Page Not Found</Typography>
            <Typography variant="body1">
              The page could not be found. If you believe this to be a bug, please{' '}
              <Link href="/contact">report it</Link>.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default NotFound404Page;
