import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        // border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0.5), // px ${theme.spacing(0.75)}px`,
        },
      },
      divider: {
        height: 28,
        margin: 4,
      },
      resultsAmount: {
        color: theme.palette.grey[600],
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    }),
  { name: 'SearchBar' },
);

export default useStyles;
