import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ContentSectionWrapper from '../../components/AppTemplate/ContentSectionWrapper/ContentSectionWrapper.component';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';
import useStyles from './Quality.page.style';
import AppConfig from '../../App.config';
import pkg from '../../../package.json'

function QualityPage() {
  const classes = useStyles();
  // const certButtons = [
  //   { 
  //     title: 'View AS9100D Certification', 
  //     href: '/docs/AS9100_Certification.pdf',
  //   },
  //   {
  //     title: 'View NADCAP Heat Treating Certification',
  //     href: '/docs/NADCAP_Heat_Treating_Certification.pdf',
  //   },
  //   {
  //     title: 'View NADCAP Welding Certification',
  //     href: '/docs/NADCAP_Welding_Certification.pdf',
  //   },
  //   {
  //     title: "View WBENC Certification",
  //     href: '/docs/WBENC_Certification.pdf',
  //   },
  //   {
  //     title: "View NMSDC Certification",
  //     href: '/docs/NMSDC_Certification.pdf',
  //   },
  // ];
  return (
    <>
      <PageHeader />
      <ContentSectionWrapper title="Quality">
        {/* <Typography variant="body1" paragraph>
          The QAP PDF is the list of definitions and explanations of the Quality Assurance
          Procurement Codes that are marked on Requisitions and Purchase Orders, when the purchase
          requires “Certification” with the material. The use of these codes communicates
          Thermocontrol Inc.’s Quality Assurance requirements, as conditions of shipment acceptance,
          to the Vendor.
        </Typography> */}
        {/* <Typography variant="body1" paragraph>
          This list includes all codes currently used on Thermocontrol, Inc. Requisitions and
          Purchase Orders. Each code is activated for the concerned Requisition and Purchase Order
          by the Quality Assurance manager checking the corresponding block and initialing the QAM
          APPROVAL: space.
        </Typography> */}
        <div className={classes.certContainer}>
          {AppConfig.certificationDocuments.map((cert) => (
          // <div className={classes.cert}>
          //   <img className={classes.certImg} src={cert.imgSrc} alt={cert.title} />
          //   <Button
          //     className={classes.certButton}
          //     key={cert.title}
          //     component="a"
          //     href={`${cert.href}?v=${pkg.version}`}
          //     variant="outlined"
          //     color="primary"
          //     startIcon={<FontAwesomeIcon icon={faFilePdf} />}
          //   >
          //     {`View ${cert.title}`}
          //   </Button>
          //   </div>
            <Card className={classes.cardRoot}>
                <CardMedia
                  component="img"
                  alt={cert.title}
                  height="100"
                  image={cert.imgSrc}
                  className={classes.cardImg}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2" align='center'>
                    {cert.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" align='center'>
                    {cert.CertDesc}
                  </Typography>
                </CardContent>
              <CardActions>
                <Button size="small" color="primary" href={cert.href} fullWidth>
                  View Certification
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      </ContentSectionWrapper>
    </>
  );
}

export default QualityPage;
