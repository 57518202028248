import { Button, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';
import AppConfig from '../../App.config';
import BoxDivider from '../../components/BoxDivider/BoxDivider.component';
import ProductGrid from '../../components/ProductGrid/ProductGrid.component';
import useStyles from './Home.page.style';

function HomePage() {
  const classes = useStyles();

  const products: any = [
    AppConfig.products[0],
    AppConfig.products[1],
    AppConfig.products[2],
    AppConfig.products[3],
  ];

  return (
    <React.Fragment>
      <section className={classes.heroSectionRoot}>
        <div className={classes.heroContainer}>
          <div className={classes.hero}>
            <img className={classes.heroLogo} src="/images/logo.webp" alt="thermocontrol logo" />
            <Typography variant="subtitle1" className={classes.subtitle}>
              Aerospace Quality Thermocouples, Harness Assemblies and Temperature Sensing
              Instrumentation
            </Typography>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <div className={classes.image} />
        </div>
        <div className={classes.heroShapeDivider}>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              className={classes.heroShapeFill}
            ></path>
          </svg>
        </div>
      </section>
      <section className={classes.productsSectionRoot}>
        <Typography className={classes.productsSectionTitle} variant="h4">
          Products
        </Typography>
        <BoxDivider />
        <Grid container spacing={3} className={classes.productGridContainer}>
          <ProductGrid products={products} gridSize={3} />
        </Grid>
        <div className={classes.productsMoreButtonContainer}>
          <Button variant="text" size="large" color="primary" component={Link} to="/products">
            More Products
          </Button>
        </div>
        <Typography className={classes.productsSectionTitle} variant="h4">
          Services
        </Typography>
        <BoxDivider />
        <div className={classes.servicesContainer}>
          <Grid container className={classes.productGridContainer}>
            {AppConfig.services
              .filter((o, i) => i < 6)
              .map((service: string) => (
                <Grid key={service} item xs={12} md={6} lg={4}>
                  <List disablePadding>
                    <ListItem>
                      <ListItemText primary={service} />
                    </ListItem>
                  </List>
                </Grid>
              ))}
          </Grid>
          <div className={classes.productsMoreButtonContainer}>
            <Button
              variant="text"
              size="large"
              color="primary"
              component={Link}
              to="/services"
            >
              More Services
            </Button>
          </div>
        </div>
      </section>
      <div className={classes.negFooterOffset}>
        <Parallax
          bgImage="/images/f15-engines.webp"
          strength={-400}
          blur={3}
          bgClassName={classes.inceptionImageBg}
        >
          <div className={classes.inceptionContainer}>
            <div className={classes.inceptionContentRoot}>
              <div className={classes.inceptionTitle}>
                <Typography variant="h4" color="primary">
                  Delivering the Data That Transports Our World
                </Typography>
              </div>
              <div className={classes.inceptionContent}>
                <Typography variant="body1" color="inherit" paragraph>
                Thermocontrol creates durable, reliable aerospace and power generation temperature 
                measurement and electronic control devices through advanced manufacturing technologies, 
                stringent quality control techniques, and exemplary customer service.
                </Typography>
                <Button
                  className={classes.dlaAboutButton}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/about"
                >
                  About Us
                </Button>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      {/* <section className={classes.dlaSectionRoot}>
        <Typography variant="h4">DLA Aviation ABVS Awards Program</Typography>
        <BoxDivider />
        <Grid container className={classes.dlaContentGrid}>
          <Grid item sm={12} md={7} className={classes.dlaContent}>
            <Typography paragraph>
              Thermocontrol, Inc. was awarded one Bronze and ten Gold medals in the now terminated
              DLA Aviation ABVS awards program.
            </Typography>
            <Typography paragraph>
              Thermocontrol, Inc. maintains and preserves the exemplary history of quality, on-time
              delivery, and service these medals represent.
            </Typography>
            <Button
              className={classes.dlaAboutButton}
              variant="contained"
              color="primary"
              component={Link}
              to="/about"
            >
              About Us
            </Button>
          </Grid>
          <Grid item sm={12} md={5} className={classes.dlaImageCell}>
            <img src="/images/dla-aviation-abvs-awards.png" alt="" />
          </Grid>
        </Grid>
      </section> */}
    </React.Fragment>
  );
}

export default HomePage;
