import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      list: {
        width: '100vw',
        maxWidth: theme.breakpoints.values.sm,
      },
      topLink: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiListItemIcon-root': {
          minWidth: 'initial',
        },
      },
      title: { fontWeight: 'bold' },
      logoImage: {
        maxHeight: 128,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: { maxHeight: 32 },
      },
      homeLink: {
        width: 'fit-content',
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        alignItems: 'center',
      },
    }),
  { name: 'MobileNav' },
);

export default useStyles;
