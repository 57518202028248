import { faAward, faCompassDrafting, faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import AppConfig from '../../App.config';
import ContentSectionWrapper from '../../components/AppTemplate/ContentSectionWrapper/ContentSectionWrapper.component';
import PageHeader from '../../components/AppTemplate/PageHeader/PageHeader.component';
import useStyles from './About.page.style';

function AboutPage() {
  const classes = useStyles();
  return (
    <>
      <PageHeader />
      <ContentSectionWrapper disablePadding>
        <Grid container>
          <Grid item sm={12} md={8}>
            <ContentSectionWrapper title="Mission Statement">
              <Typography paragraph>
                Thermocontrol creates durable, reliable aerospace and power generation temperature 
                measurement and electronic control devices through advanced manufacturing technologies,
                stringent quality control techniques, and exemplary customer service.
              </Typography>
            </ContentSectionWrapper>
            <ContentSectionWrapper title="About Us">
            <Typography paragraph>
                Thermocontrol, Inc. specializes in the <Link to="/services">research, design, manufacture,</Link> 
                and <Link to="/quality">quality assurance</Link> of high-performance temperature control 
                and monitoring devices. Thermocontrol, Inc. prides itself in producing American engineered 
                and manufactured thermocouples and thermocouple harnesses, lead assemblies, resistance temperature
                detectors, airflow temperature sensors, and other temperature devices that provide
                high accuracy and long-term reliability while operating under extreme environmental
                conditions.
              </Typography>              
              <Typography paragraph>
                Thermocontrol, Inc. supports the United State Department of Defense, its allies, and
                commercial & military aerospace OEMs with quality aerospace and power generation
                temperature instrumentation. Founded in California in 1982, the company is now
                located in Houston, TX as a division of Daily Instruments and maintains 40+ years of
                exemplary quality, on-time delivery, and manufacturing services.
              </Typography>
              <Typography paragraph>
                Thermocontrol, Inc. originated with the development of thermocouples and
                thermocouple harnesses for aircraft turbine engines. Today, the company also
                manufactures cockpit and avionics bay temperature controllers, thermocouple lead
                assemblies, and flexible harnesses for fixed-wing, rotor, space craft, and land and
                maritime power generation turbines.
              </Typography>
              <Typography paragraph>
                Thermocontrol, Inc. has extensive knowledge and experience in working with many
                products that include, but are not limited to:
              </Typography>
              <ul>
                <li>
                  <Typography component={Link} to="/products">
                    Thermocouple Harnesses (Flexible and Rigid)
                  </Typography>
                </li>
                <li>
                  <Typography component={Link} to="/products">
                    Thermocouple Immersion Probes
                  </Typography>
                </li>
                <li>
                  <Typography component={Link} to="/products">
                    Specialty Airflow Temperature Devices
                  </Typography>
                </li>
                <li>
                  <Typography component={Link} to="/products">
                    Lead assemblies
                  </Typography>
                </li>
              </ul>
              <Typography paragraph>CAGE 65410</Typography>
              <div className={classes.certImgContainer}>
                {AppConfig.footerCertifications.map((logo) => (
                  <img key={logo.imgSrc} src={logo.imgSrc} alt={logo.altText} />
                ))}
              </div>
            </ContentSectionWrapper>
          </Grid>
          <Grid item sm={12} md={4} className={classes.highlightsGrid}>
            <div className={classes.highlightsWrapper}>
              <div className={classes.highlightContainer}>
                <Avatar className={classes.avatar}>
                  <FontAwesomeIcon icon={faGears} />
                </Avatar>
                <div className={classes.highlightTextContainer}>
                  <Typography variant="h5">Vast in House Manufacturing Capabilities</Typography>
                  <Typography variant="subtitle2">
                    Range from prototyping to mass production.
                  </Typography>
                </div>
              </div>
              <div className={classes.highlightContainer}>
                <Avatar className={classes.avatar}>
                  <FontAwesomeIcon icon={faCompassDrafting} />
                </Avatar>
                <div className={classes.highlightTextContainer}>
                  <Typography variant="h5">Experienced Degreed Engineering Staff</Typography>
                  <Typography variant="subtitle2">
                    With a combined 60+ years experience in temperature instrumentation and control
                    sensor design and manufacturing.
                  </Typography>
                </div>
              </div>
              <div className={classes.highlightContainer}>
                <Avatar className={classes.avatar}>
                  <FontAwesomeIcon icon={faAward} />
                </Avatar>
                <div className={classes.highlightTextContainer}>
                  <Typography variant="h5">Highest Quality Products</Typography>
                  <Typography variant="subtitle2">
                    Designed to provide solutions for exacting temperature requirements in aerospace
                    and power gen applications.
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </ContentSectionWrapper>
    </>
  );
}

export default AboutPage;
