import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ReactNode } from 'react';
import BoxDivider from '../../BoxDivider/BoxDivider.component';
import useStyles from './ContentSectionWrapper.style';

function ContentSectionWrapper({
  className,
  id,
  title,
  children,
  disablePadding = false,
}: {
  className?: string;
  id?: string;
  title?: ReactNode;
  children?: ReactNode;
  disablePadding?: boolean;
}) {
  const classes = useStyles();

  return (
    <section id={id} className={clsx(classes.root, !disablePadding && classes.outerPadding)}>
      {title && (
        <>
          <Typography className={clsx(classes.sectionHeader, className)} variant="h4">
            {title}
          </Typography>
          <BoxDivider className={classes.boxDivider} />
        </>
      )}
      {children}
    </section>
  );
}

export default ContentSectionWrapper;
