import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dark: { color: theme.palette.common.white },
      icon: {
        color: 'inherit',
        height: 16,
      },
      iconContainer: {
        maxWidth: 24,
        minWidth: 16,
        marginRight: theme.spacing(1.5),
        justifyContent: 'center',
      },
    }),
  { name: 'ContactInfoList' },
);

export default useStyles;
