import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const standardAllEaseOut = (theme: any) =>
  `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOut} 0.1s`;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: 200,
        overflow: 'hidden',
        transition: standardAllEaseOut(theme),
        marginBottom: theme.spacing(5),
        '& img': {
          transition: standardAllEaseOut(theme),
          transform: 'translate(0%,-14%)',
          width: 2560,
          [theme.breakpoints.down('lg')]: {
            width: 1921,
          },
          [theme.breakpoints.down('sm')]: {
            width: 1250,
            transform: 'translate(0%,0%)',
          },
        },
      },
    }),
  { name: 'PageHeader.component' },
);

export default useStyles;
