const uniqueStringArray = (data: any[], subObject?: string): string[] => {
  let arrayUnique: string[] = [];

  [...data].forEach((value) => {
    if (subObject) {
      value[subObject].forEach((subObjectValue: string) => {
        arrayUnique.push(subObjectValue);
      });
    } else {
      arrayUnique.push(value);
    }
  });
  arrayUnique = arrayUnique.filter((string, i, arr) => arr.indexOf(string) === i);

  return arrayUnique;
};

export { uniqueStringArray };