import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      // Styles
      root: {
        display: 'flex',
      },
      svgContainer: { position: 'relative' },
      earthSvg: {
        maxHeight: 800,
        position: 'absolute',
        left: -250,
        top: -70,
        zIndex: -1,
      },
      contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
      },
      searchBar: {
        maxWidth: 600,
      },
    }),
  { name: 'NotFound404.page' },
);

export default useStyles;
