import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      certContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
      },
      certButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      cardRoot: {
        width: 220,
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      },
      cardImg: {
        objectFit: 'contain',
        padding: 2,
      },
      cardContent: {
        flexGrow: 1,
      },
    }),
  { name: 'Quality.page' },
);

export default useStyles;
