import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import appConfig from '../../../App.config';
import DesktopNav from '../DesktopNav/DesktopNav.component';
import MobileNav from '../MobileNav/MobileNav.component';
import useStyles from './Header.style';

// Height of Navbar element
export const navbarHeightStart: number = 80;

function PageHeader({ width }: { width: any }) {
  const classes = useStyles();

  const [isIn, setIsIn] = useState(true);
  const [scrollDirection, setScrollDirection] = useState(0);
  const [prevScrollDirection, setPrevScrollDirection] = useState(0);
  const [prevScroll, setPrevScroll] = useState(
    window.scrollY || document.documentElement.scrollTop,
  );

  const [navbarHeight, setNavbarHeight] = useState(navbarHeightStart);

  const toggleHeader = () => {
    /*
     ** Find the direction of scrollDirection
     ** 0 - initial, 1 - up, 2 - down
     */
    const curScroll = window.scrollY || document.documentElement.scrollTop;
    if (curScroll > prevScroll) {
      // scrolled up
      setScrollDirection(2);
    } else if (curScroll < prevScroll) {
      // scrolled down
      setScrollDirection(1);
    }

    if (scrollDirection !== prevScrollDirection) {
      if (scrollDirection === 2 && curScroll > navbarHeight) {
        setIsIn(false);
        setPrevScrollDirection(scrollDirection);
      } else if (scrollDirection === 1) {
        setPrevScrollDirection(scrollDirection);
        setIsIn(true);
      }
    }
    setPrevScroll(curScroll);
  };

  let location = useLocation();
  useEffect(() => {
    toggleHeader();
  }, [location, toggleHeader]);

  useEffect(() => {
    const handleUpdateNavbarHeight = () => {
      const el = document.getElementById('main-nav-bar');
      setNavbarHeight(el ? el.offsetHeight : navbarHeightStart);
    };

    window.addEventListener('scroll', toggleHeader);
    window.addEventListener('resize', handleUpdateNavbarHeight);

    return () => {
      // Remove the EventListeners to prevent a memory leak
      window.removeEventListener('scroll', toggleHeader);
      window.removeEventListener('resize', handleUpdateNavbarHeight);
    };
  }, [prevScroll, prevScrollDirection, scrollDirection, navbarHeight, toggleHeader]);

  return (
    <React.Fragment>
      <Slide in={isIn} appear timeout={scrollDirection === 0 ? 0 : 350}>
        <AppBar
          id="main-nav-bar"
          className={clsx(
            classes.appBar,
            prevScroll > navbarHeightStart && isIn && classes.appBarBg,
          )}
          position="fixed"
          color="inherit"
        >
          <Toolbar>
            <div className={classes.homeLinkContainer}>
              <Link to="/" className={classes.homeLink}>
                <img
                  className={classes.logoImage}
                  src="/images/logo.webp"
                  alt="Thermocontrol, Inc. Logo"
                />
              </Link>
            </div>
            <nav>
              {isWidthUp('lg', width) ? (
                <DesktopNav menuItems={appConfig.mainNav} />
              ) : (
                <MobileNav menuItems={appConfig.mainNav} />
              )}
            </nav>
          </Toolbar>
        </AppBar>
      </Slide>
    </React.Fragment>
  );
}

export default withWidth()(PageHeader);
