import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      sectionRoot: {
        margin: `${theme.spacing(1)}px 0`,
      },
      sectionLabel: {
        fontWeight: 'bold',
      },
    }),
  { name: 'FilterList' },
);

export default useStyles;
