import { faBarcode, faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import AboutPage from './pages/About/About.page';
import ContactPage from './pages/Contact/Contact.page';
import HomePage from './pages/Home/Home.page';
import ProductsPage from './pages/Products/Products.page';
import QualityPage from './pages/Quality/Quality.page';
import ServicesPage from './pages/Services/Services.page';
import SuppliersPage from './pages/Suppliers/Suppliers.page';

export default {
  api: '/api',
  mainNav: [
    { id: 'home', label: 'Home', path: '/', element: HomePage },
    { id: 'products', label: 'Products', path: '/products', element: ProductsPage },
    { id: 'services', label: 'Services', path: '/services', element: ServicesPage },
    { id: 'suppliers', label: 'Suppliers', path: '/suppliers', element: SuppliersPage },
    { id: 'quality', label: 'Quality', path: '/quality', element: QualityPage },
    { id: 'about', label: 'About', path: '/about', element: AboutPage },
    { id: 'contact', label: 'Contact', path: '/contact', element: ContactPage },
  ],
  contacts: [
    {
      icon: faLocationDot,
      title: '9600 W Gulf Bank Road, Houston TX 77040 US',
      href: 'https://maps.app.goo.gl/TeeVFvaD8H7Ec8kT6',
    },
    {
      icon: faEnvelope,
      title: 't-control@dailyinst.com',
      href: 'mailto:t-control@dailyinst.com',
    },
    {
      icon: faPhone,
      title: '+1 (713) 780-8600',
      href: 'tel:17137808600',
    },
    {
      icon: faBarcode,
      title: 'CAGE 65410',
      href: 'https://cage.dla.mil/Search/Details?id=1404459',
    },
  ],
  certificationDocuments: [
    { 
      imgSrc: '/images/certs/pri-registrar.webp',
      title: 'AS9100D Certification', 
      CertDesc: 'SAE AS9100 Quality Management Systems - Requirements for Aviation, Space, and Defense Organizations',
      href: '/docs/AS9100_Certification.pdf',
    },
    {
      imgSrc: '/images/certs/nadcap-white.webp',
      title: 'Nadcap Heat Treating Certification',
      CertDesc: 'Heat Treating Accreditation by Nadcap - Administered by PRI',
      href: '/docs/NADCAP_Heat_Treating_Certification.pdf',
    },
    {
      imgSrc: '/images/certs/nadcap-white.webp',
      title: 'Nadcap Welding Certification',
      CertDesc: 'Welding and Brazing Accreditation by Nadcap - Administered by PRI',
      href: '/docs/NADCAP_Welding_Certification.pdf',
    },
    {
      imgSrc: '/images/certs/wbenc-white.webp',
      title: "WBENC Certification",
      CertDesc: 'Women\'s Business Enterprise National Council',
      href: '/docs/WBENC_Certification.pdf',
    },
    {
      imgSrc: '/images/certs/nmsdc-white.webp',
      title: "NMSDC Certification",
      CertDesc: 'National Minority Supplier Development Council',
      href: '/docs/NMSDC_Certification.pdf',
    },
  ],
  footerCertifications: [
    {
      imgSrc: '/images/certs/pri-registrar.webp',
      altText: 'PRI Registrar ISO 9001:2015 - AS9100D Certified',
    },
    {
      imgSrc: '/images/certs/nadcap-white.webp',
      altText: 'Nadcap Accredited',
    },
    {
      imgSrc: '/images/certs/wbenc-white.webp',
      altText: 'WBENC Certified',
    },
    {
      imgSrc: '/images/certs/nmsdc-white.webp',
      altText: 'NMSDC Certified',
    },
  ],
  searchConfig: {
    searchableFields: ['name', 'number_tci'],
    filters: [
      {
        id: 'applications',
        title: 'Applications',
        isAndSearch: false,
      },
      {
        id: 'airframes',
        title: 'Airframes',
        isAndSearch: false,
      },
    ],
  },
  services: [
    'Precision Machining/CNC & Manual',
    'Automatic & Manual Welding',
    'Brazing (Torch & Induction)',
    'Annealing & Heat Treating',
    'Rotary Swaging',
    'Prototyping',
    'Bead-blasting',
    'Tubing & Wire Drawing',
    'Braiding (OP)',
    'Bending & Forming',
  ],
  products: [
    {
      id: 0,
      name: 'Lead Thermocouple',
      number_tci: '101-0080-2',
      number_oem: '4002T71P02',
      number_nsn: '6685-00-764-6311',
      applications: ['J85-5 Engine', 'J85-21 Engine'],
      airframes: ['Northrop F-5', 'Northrop T-38'],
      image: '/images/products/101-0080-2 J85_Lead.webp',
    },
    {
      id: 1,
      name: 'Thermocouple - Turbine Clearing, HPTCC',
      number_tci: '910-00510-1',
      number_oem: '1324M94P02 (Same as 1324M94P01)',
      number_nsn: 'To Be Assigned',
      applications: ['CFM56 Engine'],
      airframes: ['KC-135', 'Airbus A319', 'Airbus A320', 'Airbus A321', 'Boeing 737'],
      image: '/images/products/910-00510-1_CFM56_HTPCC.webp',
    },
    {
      id: 2,
      name: 'Thermocouple Harness Immersion',
      number_tci: '100089-2',
      number_oem: '6058T28P02 (Same as 6058T28P01)',
      number_nsn: '6685-01-247-2447',
      applications: ['T700 Engine', 'CT7 Engine'],
      airframes: [
        'Bell AH-1 SuperCobra',
        'Bell AH-1 Viper',
        'Bell AH-1 Venom',
        'Boeing AH-64 Apache',
        'Sikorsky UH-60 Black Hawk',
        'Sikorsky HH-60 Pave Hawk',
        'Sikorsky SH-60 Seahawk',
      ],
      image: '/images/products/100089-2_T700.webp',
    },
    {
      id: 3,
      name: 'Thermocouple Harness Immersion',
      number_tci: '100109-1',
      number_oem: '6005T78P01',
      number_nsn: '6685-00-884-9937',
      applications: ['T64 Engine'],
      airframes: [
        'Sikorsky CH-53 Sea Stallion',
        'Sikorsky CH-53E Super Stallion',
        'Sikorsky MH-53 Pave Low',
      ],
      image: '/images/products/100109-1_T64.webp',
    },
    {
      id: 4,
      name: 'Thermocouple Harness Immersion',
      number_tci: '100115-1',
      number_oem: '6005T78P03',
      number_nsn: '6685-00-884-9946',
      applications: ['T64 Engine'],
      airframes: [
        'Sikorsky CH-53 Sea Stallion',
        'Sikorsky CH-53E Super Stallion',
        'Sikorsky MH-53 Pave Low',
      ],
      image: '/images/products/100115-1_T64.webp',
    },
    {
      id: 5,
      name: 'Thermocouple Harness Immersion',
      number_tci: '100141-1',
      number_oem: '6005T78P02',
      number_nsn: '6685-00-884-9944',
      applications: ['T64 Engine'],
      airframes: [
        'Sikorsky CH-53 Sea Stallion',
        'Sikorsky CH-53E Super Stallion',
        'Sikorsky MH-53 Pave Low',
      ],
      image: '/images/products/100141-1_T64.webp',
    },
    {
      number_tci: '200033-3',
      number_oem: '625422-3-1',
      number_nsn: '5905-01-131-8079',
      id: 6,
      name: 'Sensor - Air Flow Temperature Assembly (Avionics)',
      applications: ['F/A-18 Avionics Bay'],
      airframes: ['F/A-18 Hornet A-D'],
      image: '/images/products/200033-3_F18_Avionics.webp',
    },
    {
      number_tci: '200037-1',
      number_oem: '925420-3-1',
      number_nsn: '5905-01-127-8694',
      id: 7,
      name: 'Sensor - Air Flow Temperature Assembly (Cabin)',
      applications: ['F/A-18 Avionics Bay'],
      airframes: ['F/A-18 Hornet A-D'],
      image: '/images/products/200037-1_F18_Cabin.webp',
    },
    {
      number_tci: '850501-1',
      number_oem: '2570M29P01',
      number_nsn: '2995-01-695-5603',
      id: 8,
      name: 'Thermocouple Flexible Harness EGT',
      applications: ['J85-5 Engine'],
      airframes: ['Northrop T-38'],
      image: '/images/products/850501-1_J85-5.webp',
    },
    {
      number_tci: '850551-1',
      number_oem: '2570M28P01',
      number_nsn: '2995-01-695-3676',
      id: 9,
      name: 'Thermocouple Flexible Harness EGT',
      applications: ['J85-5 Engine'],
      airframes: ['Northrop T-38'],
      image: '/images/products/850551-1_J85-5.webp',
    },
    {
      number_tci: '852101-1',
      number_oem: '2921M31P01',
      number_nsn: 'To Be Assigned',
      id: 10,
      name: 'Thermocouple Flexible Harness EGT',
      applications: ['J85-21 Engine'],
      airframes: ['Northrop F-5'],
      image: '/images/products/852101-1_J85-21.webp',
    },
    {
      number_tci: '852151-1',
      number_oem: '2921M30P01',
      number_nsn: 'To Be Assigned',
      id: 11,
      name: 'Thermocouple Flexible Harness EGT',
      applications: ['J85-21 Engine'],
      airframes: ['Northrop F-5'],
      image: '/images/products/852151-1_J85-21.webp',
    },
    {
      number_tci: 'MK-31030',
      number_oem: '1-300-177-04 (Same as 1-300-177-01, 1-300-177-02, 1-300-177-03, 1-300-177-05)',
      number_nsn: '6685-00-782-0636',
      id: 12,
      name: 'Harness Assembly Thermocouple EGT',
      applications: ['T53-13 Engine'],
      airframes: ['Bell 205', 'Bell AH-1G HueyCobra', 'Bell UH-1 Iroquois "Huey"', 'Kaman K-MAX'],
      image: '/images/products/MK-31030_T53.webp',
    },
    {
      id: 13,
      number_tci: '414-0058-2',
      number_oem: '5017T99P02',
      number_nsn: '6685-01-200-2284',
      name: 'Harness Thermocouple J85-21 Left Side',
      applications: ['J85-21 Engine'],
      airframes: ['Northrop F-5'],
      // image: '/images/products/.webp',
    },
    {
      id: 14,
      number_tci: '414-0059-2',
      number_oem: '5018T01P02',
      number_nsn: '6685-01-200-2283',
      name: 'Harness Thermocouple J85-21 Right Side',
      applications: ['J85-21 Engine'],
      airframes: ['Northrop F-5'],
      // image: '/images/products/.webp',
    },
    {
      id: 15,
      number_tci: '414-0073-5',
      number_oem: '37E501261P105',
      number_nsn: '6685-01-200-2285',
      name: 'Harness Thermocouple J85-5 Left Hand',
      applications: ['J85-5 Engine'],
      airframes: ['Northrop T-38'],
      // image: '/images/products/.webp',
    },
    {
      id: 16,
      number_tci: '414-0074-5',
      number_oem: '37E501260P105',
      number_nsn: '6685-01-303-4169',
      name: 'Harness Thermocouple J85-5 Right Hand',
      applications: ['J85-5 Engine'],
      airframes: ['Northrop T-38'],
      // image: '/images/products/.webp',
    },
    {
      id: 17,
      number_tci: '416-0075-6',
      number_oem: '108R532P6',
      number_nsn: '2925-01-143-0092',
      name: 'Thermocouple Harness, Bottom',
      applications: ['J79 Engine'],
      airframes: [
        'McDonnell Douglas F-4 Phantom II',
        'Lockheed F-104 Starfighter',
        'Israel Aircraft Industries Kfir',
      ],
      // image: '/images/products/.webp',
    },
    {
      id: 18,
      number_tci: '416-0076-7',
      number_oem: '108R531P7',
      number_nsn: '6685-00-884-8601',
      name: 'Thermocouple Harness, Top',
      applications: ['J79 Engine'],
      airframes: [
        'McDonnell Douglas F-4 Phantom II',
        'Lockheed F-104 Starfighter',
        'Israel Aircraft Industries Kfir',
      ],
      // image: '/images/products/.webp',
    },
    {
      id: 19,
      number_tci: '100015-2',
      number_oem: '3039M34P02',
      number_nsn: '6685-01-136-4868',
      name: 'Probe Thermocouple',
      applications: ['J79 Engine'],
      airframes: [
        'McDonnell Douglas F-4 Phantom II',
        'Lockheed F-104 Starfighter',
        'Israel Aircraft Industries Kfir',
      ],
      // image: '/images/products/.webp',
    },
    {
      id: 20,
      number_tci: '100027-1',
      number_oem: '23006614',
      number_nsn: '6685-01-291-4375',
      name: 'Thermocouple, Turbine Outlet',
      applications: ['T56 Engine'],
      airframes: ['Lockheed C-130 Hercules'],
      // image: '/images/products/.webp',
    },
    {
      id: 21,
      number_tci: '100121-1',
      number_oem: '6851960',
      number_nsn: '6150-00-057-4278',
      name: 'Harness - Collector Thermocouple Assembly Right Hand',
      applications: ['T56 Engine'],
      airframes: ['Lockheed C-130 Hercules'],
      // image: '/images/products/.webp',
    },
    {
      id: 22,
      number_tci: '100127-1',
      number_oem: '6851956',
      number_nsn: '6150-00-057-4277',
      name: 'Harness - Collector Thermocouple Assembly Left Hand',
      applications: ['T56 Engine'],
      airframes: ['Lockheed C-130 Hercules'],
      // image: '/images/products/.webp',
    },
  ],
};
