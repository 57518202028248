import { createStyles, makeStyles } from '@material-ui/core/styles';
import theme from '../../theme/mui-theme';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        width: 65,
        height: 5,
        background: theme.palette.primary.main,
      },
    }),
  { name: 'AppTemplate' },
);

export default useStyles;
