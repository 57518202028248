import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      searchRoot: {
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px 0 ${theme.spacing(4)}px`,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
        },
      },
      searchBarContainer: {
        paddingTop: '0 !important',
      },
      searchBar: { width: '100%' },
      filtersContainer: {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
      resultsRoot: {
        marginTop: 0,
      },

      productGridContainer: {
        marginTop: theme.spacing(2),
      },
      productCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      },
      productCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
      },
      productCardImg: {},
      productCardContent: {
        width: '100%',
        background: theme.palette.common.white,
        borderTop: '2px solid',
        borderTopColor: theme.palette.primary.light,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },

      divider: { margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px 0` },
      drawerFiltersContainer: {
        padding: theme.spacing(2),
        width: '100vw',
        maxWidth: theme.breakpoints.values.sm,
      },
      drawerFiltersTitle: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      servicesGridContainer: {},
    }),
  { name: 'Products.page' },
);

export default useStyles;
